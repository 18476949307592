<template>
  <div>
    <router-view :key="$route.fullPath" />
    <b-overlay
      :show="show"
      rounded="sm"
      @shown="onShown"
      @hidden="onHidden"
    >
      <b-alert
        v-if="raidActive"
        variant="info"
        show
      >
        <div class="alert-body">
          <span><strong>Raid Active!</strong> Messages are sent to raided Channel: {{ this.raidedChannel }}</span>
        </div>
      </b-alert>
      <b-card
        title="Instant message"
      >
        <b-card-text class="mb-0">
          <!--        <span>Start/stop </span>-->
          <!--        <code>switch</code>-->
          <!--        <span> to </span>-->
          <!--        <code>true</code>-->
        </b-card-text>

        <!-- switch -->
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Message"
            >
              <b-row>
                <b-col>
                  <b-form-input
                    id="instantMessage"
                    v-model="instantMessage"
                    type="text"
                    placeholder="Message"
                    @keyup.enter="sendInstantMessage"
                  />
                </b-col>
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="themeColor"
                    @click="sendInstantMessage"
                  >
                    <feather-icon
                      icon="PlayIcon"
                      class="mr-25"
                    />
                    <span>Send Message {{ raidedText }}</span>
                  </b-button>
                </b-col>
              </b-row>

            </b-form-group>
          </b-col>
          <b-col />
        </b-row>
        <!--        <b-button-->
        <!--          v-if="showstop"-->
        <!--          v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
        <!--          variant="danger"-->
        <!--          class="mb-1"-->
        <!--          @click="stopbot"-->
        <!--        >-->
        <!--          <feather-icon-->
        <!--            icon="AlertOctagonIcon"-->
        <!--            class="mr-25"-->
        <!--          />-->
        <!--          <span>Stop Bot</span>-->
        <!--        </b-button>-->
      </b-card>

      <b-row>
        <b-col>
          <b-card title="Hotkey 1">
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :text="hotkeysettings[0].settings[0]"
              variant="outline-primary"
              block
            >
              <b-dropdown-header>
                Patterns
              </b-dropdown-header>
              <b-dropdown-item
                v-for="(item) in items"
                :id="item.id"
                :key="item.id"
                :name="item.name"
                @click="changepattern(1,item.name, item.uid, false)"
              >
                {{ item.name }}
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-header>
                Conversations
              </b-dropdown-header>
              <b-dropdown-item
                v-for="(item) in conversations"
                :id="item.id"
                :key="item.id"
                :name="item.name"
                @click="changepattern(1,item.name, item.uid, true)"
              >
                {{ item.name }}
              </b-dropdown-item>
            </b-dropdown>

            <b-form-group
              class="mx-auto mt-1"
              label="Chat-Interval (seconds)"
            >
              <div class="mt-3">
                <vue-slider
                  v-model="hotkeysettings[0].slider"
                  :min="0"
                  :max="600"
                  :tooltip="'always'"
                  :direction="'ltr'"
                  :drag-on-click="true"
                />
              </div>
            </b-form-group>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="themeColor"
              class="mb-1"
              block
              @click="startbot(1)"
              @keyup.ctrl="startbot(1)"
            >
              <feather-icon
                icon="PlayIcon"
                class="mr-25"
              />
              <span>Send Messages {{ raidedText }}</span>

            </b-button>
          </b-card>
        </b-col>
        <b-col>
          <b-card title="Hotkey 2">
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :text="hotkeysettings[1].settings[0]"
              variant="outline-primary"
              block
            >
              <b-dropdown-header>
                Patterns
              </b-dropdown-header>
              <b-dropdown-item
                v-for="(item) in items"
                :id="item.id"
                :key="item.id"
                :name="item.name"
                @click="changepattern(2,item.name, item.uid, false)"
              >
                {{ item.name }}
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-header>
                Conversations
              </b-dropdown-header>
              <b-dropdown-item
                v-for="(item) in conversations"
                :id="item.id"
                :key="item.id"
                :name="item.name"
                @click="changepattern(2,item.name, item.uid, true)"
              >
                {{ item.name }}
              </b-dropdown-item>
            </b-dropdown>

            <b-form-group
              class="mx-auto mt-1"
              label="Chat-Interval (seconds)"
            >
              <div class="mt-3">
                <vue-slider
                  v-model="hotkeysettings[1].slider"
                  :min="0"
                  :max="600"
                  :tooltip="'always'"
                  :direction="'ltr'"
                  :drag-on-click="true"
                />
              </div>
            </b-form-group>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="themeColor"
              class="mb-1"
              block
              @click="startbot(2)"
            >
              <feather-icon
                icon="PlayIcon"
                class="mr-25"
              />
              <span>Send Messages {{ raidedText }}</span>

            </b-button>
          </b-card>
        </b-col>
        <b-col>
          <b-card title="Hotkey 3">
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :text="hotkeysettings[2].settings[0]"
              variant="outline-primary"
              block
            >
              <b-dropdown-header>
                Patterns
              </b-dropdown-header>
              <b-dropdown-item
                v-for="(item) in items"
                :id="item.id"
                :key="item.id"
                :name="item.name"
                @click="changepattern(3,item.name, item.uid, false)"
              >
                {{ item.name }}
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-header>
                Conversations
              </b-dropdown-header>
              <b-dropdown-item
                v-for="(item) in conversations"
                :id="item.id"
                :key="item.id"
                :name="item.name"
                @click="changepattern(3,item.name, item.uid, true)"
              >
                {{ item.name }}
              </b-dropdown-item>
            </b-dropdown>

            <b-form-group
              class="mx-auto mt-1"
              label="Chat-Interval (seconds)"
            >
              <div class="mt-3">
                <vue-slider
                  v-model="hotkeysettings[2].slider"
                  :min="0"
                  :max="600"
                  :tooltip="'always'"
                  :direction="'ltr'"
                  :drag-on-click="true"
                />
              </div>
            </b-form-group>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="themeColor"
              class="mb-1"
              block
              @click="startbot(2)"
            >
              <feather-icon
                icon="PlayIcon"
                class="mr-25"
              />
              <span>Send Messages {{ raidedText }}</span>

            </b-button>
          </b-card>
        </b-col>
      </b-row>
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="cash"
            font-scale="3"
            animation="pulse"
          />
          <p id="cancel-label">
            Please buy a license
          </p>
        </div></template>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components,no-unused-vars */
import {
  BFormCheckbox, BOverlay, BDropdownHeader, BAlert, BCardText, BCard, BFormInput, BButton, BFormGroup, BCol, BRow, BIcon, BIconCash, BFormValidFeedback, BDropdown, BDropdownItem, BDropdownDivider, BFormInvalidFeedback, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import VueSlider from 'vue-slider-component'
import firebase from 'firebase'
import VueHotkey from 'v-hotkey'

export default {
  components: {
    BCol,
    BOverlay,
    BRow,
    BFormCheckbox,
    BCard,
    BDropdownHeader,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BDropdown,
    BDropdownItem,
    BAlert,
    BDropdownDivider,
    VueSlider,
    BFormInput,
    BButton,
    VBTooltip,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BIconCash,
    BIcon,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      instantMessage: null,
      raidedChannel: 'error',
      raidActive: false,
      timeslider: [30, 180],
      minutes: 0,
      seconds: 15,
      max: 20,
      valueslider: 15,
      nextTodoId: 1,
      test3: 'hi',
      code: null,
      show: false,
      twitch: '',
      showstart: true,
      showstop: false,
      items: [],
      hotkeysettings: [], // 1.Name 2.Conversation 3.ID
      conversations: [],
    }
  },
  computed: {
    themeColor() {
      if (this.raidActive) return 'info'
      return 'success'
    },
    raidedText() {
      if (this.raidActive) return 'to raided Channel'
      return ''
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
  watch: {
    timeslider() {
      const x = this.timeslider / 60
      const comma = (x * 10) % 10
      this.minutes = x - comma
      this.seconds = this.timeslider % 60
    },
    valueslider() {
      if (this.valueslider > this.max) {
        this.valueslider = this.max
      }
    },
  },
  created() {
    window.addEventListener('keypress', this.checkHotkey)
  },
  destroyed() {
    window.removeEventListener('keypress', this.checkHotkey)
  },
  mounted() {
    const userData = getUserData()
    const sx = this.$firebase.firestore()
    sx.collection('User')
      .doc(userData.id)
      .get()
      .then(snap => {
        // console.log(snap.data().Package)
        if (snap.data().Package === 'None') {
          this.show = true
          this.hotkeysettings = [
            {
              slider: [
                11,
                18,
              ],
              settings: [
                'TestConversation with at',
                true,
                'QKaoRCMkMsjjDvQvdNUO',
              ],
            },
            {
              slider: [
                1,
                15,
              ],
              settings: [
                'WAIT',
                false,
                'YoTXQGgvWwZIq6LRYaYz',
              ],
            },
            {
              slider: [
                50,
                200,
              ],
              settings: [
                'Select',
                true,
                'xx',
              ],
            },
            {
              slider: [
                50,
                200,
              ],
              settings: [
                'Select',
                true,
                'xx',
              ],
            },
            {
              slider: [
                50,
                200,
              ],
              settings: [
                'Select',
                true,
                'xx',
              ],
            },
            {
              settings: [
                'Select',
                true,
                'xx',
              ],
              slider: [
                50,
                200,
              ],
            },
            {
              settings: [
                'Select',
                true,
                'xx',
              ],
              slider: [
                50,
                200,
              ],
            },
            {
              slider: [
                50,
                200,
              ],
              settings: [
                'Select',
                true,
                'xx',
              ],
            },
            {
              settings: [
                'Select',
                true,
                'xx',
              ],
              slider: [
                50,
                200,
              ],
            },
          ]
        } else {
          this.raidedChannel = snap.data().raid.twitchname
          this.raidActive = snap.data().raid.active
          if (this.raidActive === true) {
            this.$store.state['app/raidedChannel'] = this.raidedChannel
            this.$store.state['app/raidActive'] = true
          }
          this.show = false
          this.hotkeysettings = snap.data().Chatboard.Hotkeysettings
          if (this.hotkeysettings.length < 3) {
            this.hotkeysettings.push({ settings: ['Select', true, 'xx'], slider: [50, 200] })
            this.hotkeysettings.push({ settings: ['Select', true, 'xx'], slider: [50, 200] })
            this.hotkeysettings.push({ settings: ['Select', true, 'xx'], slider: [50, 200] })
          }
          if (this.hotkeysettings[0].settings === undefined) {
            console.log('undefined')
          }
        }
      })
      .catch(error => {
        console.log('ERRROR')
        console.log(error)
        this.hotkeysettings.push({ settings: ['Select', true, 'xx'], slider: [50, 200] })
        this.hotkeysettings.push({ settings: ['Select', true, 'xx'], slider: [50, 200] })
        this.hotkeysettings.push({ settings: ['Select', true, 'xx'], slider: [50, 200] })
        console.log(this.hotkeysettings)
      })
    sx.collection('User')
      .doc(userData.id)
      .get()
      .then(snap => {
        // console.log(snap.data().Package)
        if (snap.data().Package === 'None') {
          this.show = true
        }
      })
    sx.collection('User')
      .doc(userData.id)
      .collection('Chatlists')
      .get()
      .then(snap => {
        // console.log('SNAPDOC' + snap.get())
        snap.forEach(doc => {
          this.items.push({
            id: this.nextTodoId += 1,
            name: doc.data().name,
            uid: doc.id,
          })
        })
      })
    sx.collection('User')
      .doc(userData.id)
      .collection('Conversations')
      .get()
      .then(snap => {
        // console.log('SNAPDOC' + snap.get())
        snap.forEach(doc => {
          this.conversations.push({
            id: this.nextTodoId += 1,
            name: doc.data().name,
            uid: doc.id,
          })
        })
      })
  },
  methods: {
    checkHotkey(e) {
      if (String.fromCharCode(e.keyCode) === '1') {
        this.startbot(1)
      }
      if (String.fromCharCode(e.keyCode) === '2') {
        this.startbot(2)
      }
      if (String.fromCharCode(e.keyCode) === '3') {
        console.log(this.$route.name)
        this.startbot(3)
      }
    },
    sendInstantMessage() {
      const userData = getUserData()
      const sx = this.$firebase.firestore()
      sx.collection('User')
        .doc(userData.id)
        .get()
        .then(snap => {
          sx.collection('System')
            .doc('NGROK')
            .get()
            .then(snap2 => {
              // fetch(`http://localhost:5000/sendInstantMessage/${userData.id}`)
              fetch(`https://${snap2.data().ChatboardLink}.ngrok.io/sendInstantMessage/${userData.id}`)
                .then(response => response.json())
              // eslint-disable-next-line no-unused-vars
                .then(data => {
                  // console.log(data)
                  sx.collection('User')
                    .doc(userData.id)
                    .update({
                      instantMessage: this.instantMessage,
                      // Chatboard: {
                      //   instantMessage: this.instantMessage,
                      // },
                    })
                    .then(() => {
                      this.$swal({
                        title: 'Sending message',
                        text: 'Sending message',
                        icon: 'success',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                      })
                    })
                    .catch(error => {
                      console.log(error)
                      this.$swal({
                        title: 'Error!',
                        text: ' Something went wrong with your Account, please contact our Support via Discord or Mail!',
                        icon: 'error',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                      })
                    })
                })
                .catch(error => {
                  console.log(error)
                  this.$swal({
                    title: 'Error!',
                    text: ' Something went wrong with your Account, please contact our Support via Discord or Mail!',
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                })
            })
        })
    },
    changepattern(hotkeys, pattern, chatID, isConversation) {
      const hotkey = hotkeys - 1
      this.test3 = pattern
      this.hotkeysettings[hotkey].settings[0] = pattern
      this.hotkeysettings[hotkey].settings[1] = isConversation
      this.hotkeysettings[hotkey].settings[2] = chatID
      this.$set(this.hotkeysettings[hotkey].settings, 0, pattern)
      console.log(this.hotkeysettings[hotkey])
      this.saveall()
    },
    saveall() {
      console.log('saved everything')
      console.log(this.hotkeysettings)
      const userData = getUserData()
      const sx = this.$firebase.firestore()
      sx.collection('User')
        .doc(userData.id)
        .update({
          Chatboard: {
            // Hotkeysettings: [{ settings: ['GG1', true, 'VWKRf4J4dMiiA5ylMcUx'], slider: [15, 150] }],
            Hotkeysettings: this.hotkeysettings,
          },
        })
    },
    outputhotkey() {
      console.log(this.hotkeysettings)
    },
    startbot(hotnumber) {
      this.saveall()
      console.log(hotnumber)
      const userData = getUserData()
      const sx = this.$firebase.firestore()
      sx.collection('User')
        .doc(userData.id)
        .get()
        .then(snap => {
          sx.collection('System')
            .doc('NGROK')
            .get()
            .then(snap2 => {
              // console.log(snap2)
              // fetch(`http://localhost:5000/startChatboard/${hotnumber}/${userData.id}`)
              fetch(`https://${snap2.data().ChatboardLink}.ngrok.io/startChatboard/${hotnumber}/${userData.id}`)
                .then(response => response.json())
              // eslint-disable-next-line no-unused-vars
                .then(data => {
                  // console.log(data)
                  sx.collection('User')
                    .doc(userData.id)
                    .set({
                      Botpanel: {
                        chatinterval: this.timeslider,
                      },
                    }, { merge: true })
                    .then(() => {
                      this.showstop = true
                      this.showstart = false
                      this.$swal({
                        title: 'Start sending messages',
                        text: 'Start sending messages',
                        icon: 'success',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                      })
                    })
                    .catch(error => {
                      console.log(error)
                      this.$swal({
                        title: 'Error!',
                        text: ' Something went wrong with your Account, please contact our Support via Discord or Mail!',
                        icon: 'error',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                      })
                    })
                })
                .catch(error => {
                  console.log(error)
                  this.$swal({
                    title: 'Error!',
                    text: ' Something went wrong with your Account, please contact our Support via Discord or Mail!',
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                })
            })
        })
    },
    onShown() {
      // Focus the cancel button when the overlay is showing
    },
    onHidden() {
      // Focus the show button when the overlay is removed
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
